import React, { createContext, useReducer, useEffect, useContext, useCallback, useMemo, useState } from "react";
import UserContext from './UserContext';
import Context from './ContextUtils';
import HomeContext from './HomeContext';
import moment from 'moment';


const StepNames = {
    STEP1: "STEP1",
    STEP2: "STEP2",
    STEP3: "STEP3",
    STEP4: "STEP4",
    STEP5: "STEP5"
}
const InitialOnboardingContext = {
    steps: {
        [StepNames.STEP1]: false,
        [StepNames.STEP2]: false,
        [StepNames.STEP3]: false,
        [StepNames.STEP4]: false,
        [StepNames.STEP5]: false
    },
    showOnboarding: false,
    expireInSeconds: 0,
};
const OnboardingContext = createContext();

const ONBOARDING_ACTIONS = {
    SET_STEP: "SET_STEP",
    SET_SHOW_ONBOARDING: "SET_SHOW_ONBOARDING",
    SET_EXPIRE_SECONDS: "SET_EXPIRE_SECONDS",
}


const reducer = (state, { type, ...rest }) => {

    switch (type) {

        case ONBOARDING_ACTIONS.SET_STEP: {
            return { ...state, steps: { ...state.steps, [rest.step]: rest.status } };
        }

        case ONBOARDING_ACTIONS.SET_SHOW_ONBOARDING: {
            return { ...state, showOnboarding: rest.status };
        }

        case ONBOARDING_ACTIONS.SET_EXPIRE_SECONDS: {
            return { ...state, expireInSeconds: rest.status };
        }

        default: {
            return state;
        }
    }
};

let onBoardingStatusTimer;
let intervalOTPResend;
const OnboardingContextContainer = (props) => {
    const [state, dispatch] = useReducer(reducer, InitialOnboardingContext);
    const { store } = useContext(Context);
    const { 
        mobileVerified, 
        //isTeamMember,
        plan } = useContext(UserContext);
    const { recentCampaigns, isLoadingCampaigns } = useContext(HomeContext);

    const enableResendTimer = useCallback(() => {
        const timeNow = moment();
        sessionStorage.setItem("startTimeToResend", timeNow);
        timerOTP();
    }, [])

    const setStep = useCallback((step, status) => {
        dispatch({ type: ONBOARDING_ACTIONS.SET_STEP, step: step, status });
    }, [dispatch])
    const findCurrentStep = useCallback(() => {

        if (mobileVerified) {
            setStep(StepNames.STEP1, true);
        }
        if (store.people.peopleCount > 0) {
            setStep(StepNames.STEP2, true);
        }
        if (plan !== "sub_starter") {
            setStep(StepNames.STEP5, true);
        }
        if (store.common.senderIds.SMS.filter(({ ownerId }) => ownerId !== "default").length > 0 || store.common.senderIds.EMAIL.length > 0) {
            setStep(StepNames.STEP4, true);
        }



    }, [store.common.senderIds, store.people.peopleCount, setStep, mobileVerified, plan]);



    useEffect(() => {
        if (recentCampaigns.length > 0) {
            setStep(StepNames.STEP3, true);
        }
    }, [recentCampaigns])

    useEffect(() => {
        findCurrentStep();
    }, [store.common.senderIds.SMS, store.people.peopleCount, mobileVerified])

    useEffect(() => {
        if (onBoardingStatusTimer) {
            clearTimeout(onBoardingStatusTimer);
        }
        onBoardingStatusTimer = setTimeout(() => {
            if ((isLoadingCampaigns || store.people.isLoadingPeople || store.common.loadingSenderIds) || (state.steps[StepNames.STEP1] && state.steps[StepNames.STEP2] && state.steps[StepNames.STEP3] && state.steps[StepNames.STEP4] && state.steps[StepNames.STEP5])) {
                dispatch({ type: ONBOARDING_ACTIONS.SET_SHOW_ONBOARDING, status: false });
            } else {
                dispatch({ type: ONBOARDING_ACTIONS.SET_SHOW_ONBOARDING, status: true });
            }
        }, 1000)

    }, [state.steps, isLoadingCampaigns, store.people.isLoadingPeople, store.common.loadingSenderIds]);

    useEffect(() => {

        const startTimeStamp = sessionStorage.getItem("startTimeToResend");
        const timeNow = moment();

        const timeDifference = timeNow.diff(startTimeStamp, "seconds")


        if (intervalOTPResend) {
            clearTimeout(intervalOTPResend);
        }

        const currentSeconds = 120 - timeDifference;

        intervalOTPResend = setTimeout(() => {
            if (timeDifference < 121 && timeDifference > 0 ) {
                dispatch({ type: ONBOARDING_ACTIONS.SET_EXPIRE_SECONDS, status: currentSeconds });
            } else {
                dispatch({ type: ONBOARDING_ACTIONS.SET_EXPIRE_SECONDS, status: 0 });
            }
        }, 1000)
    }, [state.expireInSeconds]);


    const allowResendOTP = useMemo(() => {
        if (state.expireInSeconds === 0) {
            return true;
        }
        else {
            return false;
        }
    }, [state.expireInSeconds])



    const timerOTP = useCallback(() => {

        const startTimeStamp = sessionStorage.getItem("startTimeToResend");
        const timeNow = moment();

        const timeDifference = timeNow.diff(startTimeStamp, "seconds")
                
        const currentSeconds = 120 - timeDifference;

        if (timeDifference > 120) {
            sessionStorage.removeItem("startTimeToResend");
            dispatch({ type: ONBOARDING_ACTIONS.SET_EXPIRE_SECONDS, status: 0 });
        }
        else{
            dispatch({ type: ONBOARDING_ACTIONS.SET_EXPIRE_SECONDS, status: currentSeconds });
        }

    }, [dispatch])

    const value = { ...state, allowResendOTP, enableResendTimer, expireInSeconds: state.expireInSeconds };
    return (
        <OnboardingContext.Provider value={value}>{props.children}</OnboardingContext.Provider>
    );
}


export default OnboardingContext;
export { OnboardingContext, OnboardingContextContainer, StepNames };