
import React, { useEffect, useContext } from 'react';
import './../../../../lib/react-multistep/prog-tracker.css';
import Multistep from './../../../../lib/react-multistep/multistep';
import HomeContext from './../../../../contexts/HomeContext';
import { TemplateNameInputContainer, TemplateGalleryModalContainer } from './../../../../redux/containers/TemplateContainer';

import {
    CampaignSetupContainer,
    CampaignDesignContainer,
    CampaignConfirmContainer
} from './../../../../redux/containers/V2/CampaignCreateContainer';

const CreateCampaign = ({ isDisableNextStep1SMS, isDisableNextStep1Email, isDisableNextStep2, name, isScheduled, launchOn, transports, selectedPeople, sms, email, selectedSegment, onCreateCampaign, showSelectSegment, showSelectEvent, isDisableEventNextBtn, campaignType, activeStep, isDisableSegmentNextBtn, selectedEvent, excludedPeople, selectedFilters, isCreatingCampaign, history, messenger, onChangeActiveStep }) => {
    const { loadRecentCampaigns } = useContext(HomeContext);
    useEffect(() => {
        if (transports.length === 0) {
            history.push("/engagement/campaigns");
        }
    }, [transports])

    const steps = [
        {
            name: 'Setup',
            isDisableNextBtn: transports[0] === "SMS" ? isDisableNextStep1SMS : isDisableNextStep1Email,
            component: <CampaignSetupContainer />
        }

    ];
    steps.push(
        {
            name: 'Design',
            isDisableNextBtn: isDisableNextStep2,
            component: <CampaignDesignContainer />
        });
    steps.push({
        name: 'Confirm',
        nextBtnName: 'Create',
        isDisableNextBtn: isCreatingCampaign,
        showNextBtn: true,
        onClickNext: () => {
            onCreateCampaign(name, isScheduled, launchOn, transports, selectedPeople, sms, email, selectedSegment, campaignType, selectedEvent, excludedPeople, selectedFilters, history, messenger,loadRecentCampaigns)
        },
        component: <CampaignConfirmContainer />
    });
console.debug(">>>Selected segment:",selectedSegment);
    return (
        <div className='container step-progress d-flex flex-column flex-fill'>
            <Multistep steps={steps} activeStep={activeStep} fixedStepBar onChangeActiveStep={onChangeActiveStep}>

            </Multistep>
            <TemplateNameInputContainer />
            <TemplateGalleryModalContainer />
        </div>
    );
};
export default CreateCampaign;