/**
 * Created by madura on 1/19/17.
 */
import React, { useRef, useState, useCallback, useEffect } from 'react';
import { ShoutOUTService, AnalyticsService, UtilService } from './../../services/Services';
//import Shoutout from './../../services/Shoutout';
import Avatar from 'react-user-avatar';
import {
    Nav,
    Image,
    Popover,
    Button,
    Overlay,
    FaIcon,
    Accordion
} from '@shoutout-labs/shoutout-themes';
import { faTachometerAlt, faIdBadge, faUsers, faBullhorn, faSignInAlt, faInbox, faHeart, faHandshake, faChartLine, faPlug, faCogs, faBriefcase, faCreditCard, faUser, faSignOutAlt, faInfo, faComment, faLifeRing, faCode, faBook } from '@shoutout-labs/shoutout-themes/es/FaIconsSolid';
import Constants from './../../Constants';
import UserContext from './../../contexts/UserContext';

import { withRouter, NavLink, Link } from 'react-router-dom';
import './SideNavigationBar.css';


const SideNavigationBar = ({ history }) => {
    const [switchProductMenuShow, setSwitchProductMenuShow] = useState(false);
    const [campaignMenuShow, setCampaignMenuShow] = useState(false);
    const [sendersMenuShow, setSendersMenuShow] = useState(false);
    const [productsMenuShow, setProductsMenuShow] = useState(false);
    const [dashboardMenu, setDashboardMenu] = useState(true);
    const [peopleMenu, setPeopleMenu] = useState(false);
    const [country, setCountry] = useState('lk')

    const changePopoverVisibility = useCallback(() => {
        setSwitchProductMenuShow(!switchProductMenuShow)
    }, [setSwitchProductMenuShow])

    const hidePopoverVisibility = useCallback(() => {
        setSwitchProductMenuShow(false)
    }, [setSwitchProductMenuShow])


    // const toggleCampaigns = useCallback(() => {
    //     setCampaignMenuShow(!campaignMenuShow)
    // }, [setSendersMenuShow, setCampaignMenuShow, setProductsMenuShow])

    // const toggleSenders = useCallback(() => {
    //     setSendersMenuShow(!sendersMenuShow)
    // }, [setSendersMenuShow, setCampaignMenuShow, setProductsMenuShow, setDashboardMenu])

    // const toggleProducts = useCallback(() => {
    //     setProductsMenuShow(!productsMenuShow)
    // }, [setSendersMenuShow, setCampaignMenuShow, setProductsMenuShow])

    // const toggleDashboard = useCallback(() => {
    //     setDashboardMenu(!dashboardMenu);
    // }, [setDashboardMenu])

    // const togglePeople = useCallback(() => {
    //     setPeopleMenu(!peopleMenu)
    // }, [setPeopleMenu])

    const triggerSupportChat = useCallback(() => {
        AnalyticsService.trackActivity({
            "name": "trigger-chatBox"
        });
    }, [])

    useEffect(() => {
        UtilService.getIpInfo().then(resp => {
            if (resp) {
                setCountry(resp.country);
            }
        });
    }, [])
    return (
        <UserContext.Consumer>
            {
                ({ userRole, name, smsCreditsOnDemand, smsCreditsPlan, emailCreditsOnDemand, emailCreditsPlan, logout, token, scopes }) => {
                    return (
                        <div className="sidebar-wrapper bg-white">

                            <div>
                                <small className="link-header">Menu</small>

                                <NavLink to="/dashboard" className="link text-center" activeClassName="active-link" id="side-nav-link-dashboard">
                                    <FaIcon icon={faTachometerAlt} className="side-nav-icon" />
                                    <span className="link-text" id='dashbordname'>Dashboard</span>
                                </NavLink>

                                {/* <Nav.Item as={Link} to="/dashboard" className={`link text-center text-dark ${dashboardMenu ? "active-link" : ''}`} onClick={toggleDashboard} id="side-nav-link-dashboard">
                                <FaIcon icon={faTachometerAlt} className="side-nav-icon" />
                                <span className="link-text">Dashboard</span>
                            </Nav.Item> */}

                                {scopes[Constants.USER_ACCESS_CONTROLS.MODULES.PEOPLE.moduleId].read &&
                                    // <Nav.Item as={Link} to="/people" className={`link text-center text-dark ${peopleMenu ? "active-link" : ''}`} onClick={togglePeople} id="side-nav-link-people">
                                    //     <FaIcon icon={faUsers} className="side-nav-icon" />
                                    //     <span className="link-text">People</span>
                                    // </Nav.Item>

                                    <NavLink to="/people" className="link text-center" activeClassName="active-link" id="side-nav-link-dashboardl">
                                        <FaIcon icon={faUsers} className="side-nav-icon" />
                                        <span className="link-text" id='contactname'>Contacts</span>
                                    </NavLink>
                                }

                                {Constants.BUILD_APP === Constants.APP_ENGAGEMENT ?
                                    <>
                                        {scopes[Constants.USER_ACCESS_CONTROLS.MODULES.CAMPAIGNS.moduleId].read &&
                                            <>
                                                {/* <Nav.Item onClick={toggleCampaigns} className={`cursor-pointer link text-center text-dark ${campaignMenuShow ? "active-link" : ''}`} id="side-nav-link-campaigns">
                                                <FaIcon icon={faBullhorn} className="side-nav-icon" />
                                                <span className="link-text">Campaigns</span>
                                            </Nav.Item> */}
                                                <NavLink to="/engagement/campaigns" className="link text-center" activeClassName="active-link" id="side-nav-link-dashboard">
                                                    <FaIcon icon={faBullhorn} className="side-nav-icon" />
                                                    <span className="link-text" id='campaignsname'>Campaigns</span>
                                                </NavLink>
                                                {/* <div className={campaignMenuShow ? "d-flex flex-column" : "d-none"}>
                                                <Link to="/engagement/campaigns" onClick={toggleCampaigns} className="text-dark link-sub p-0 pl-3"><small>SMS</small></Link>
                                                <Link to="/engagement/campaigns" onClick={toggleCampaigns} className="text-dark link-sub p-0 pl-3"><small>Email</small></Link>
                                            </div> */}

                                                <NavLink to={(country === "us" || country === "ca") ? "/senders/numbers" : "/senders"} className="link text-center" activeClassName="active-link" id="side-nav-link-dashboard">
                                                    <FaIcon icon={faIdBadge} className="side-nav-icon" />
                                                    {(country === "us" || country === "ca") ?
                                                        <span className="link-text">Phone Numbers</span>
                                                        : <span className="link-text">Senders</span>
                                                    }
                                                </NavLink>

                                                {/* <Nav.Item onClick={toggleSenders} className={`cursor-pointer link text-center text-dark ${sendersMenuShow ? "active-link" : ''}`} id="side-nav-link-inbound">
                                                <FaIcon icon={faIdBadge} className="side-nav-icon" />
                                                <span className="link-text">Senders</span>
                                            </Nav.Item>
                                            <div className={sendersMenuShow ? "d-flex flex-column" : "d-none"}>
                                                <Link to="/senders" className="text-dark link-sub p-0 pl-3"><small>SMS</small></Link>
                                                <Link to="/senders" className="text-dark link-sub p-0 pl-3"><small>Numbers</small></Link>
                                                <Link to="/senders" className="text-dark link-sub p-0 pl-3"><small>Email</small></Link>
                                            </div> */}

                                            </>
                                        }

                                        {scopes[Constants.USER_ACCESS_CONTROLS.MODULES.RESPONSE.moduleId].read &&
                                            <NavLink to="/engagement/responses" className="link text-center" activeClassName="active-link" id="side-nav-link-responses">
                                                <FaIcon icon={faSignInAlt} className="side-nav-icon" />
                                                <span className="link-text" id='responsesname' >Responses</span>
                                            </NavLink>
                                        }
                                        <NavLink to="/engagement/inbound" className="link text-center" activeClassName="active-link" id="side-nav-link-inbound">
                                            <FaIcon icon={faInbox} className="side-nav-icon" />
                                            <span className="link-text" id='inboundname'  >Inbound</span>
                                        </NavLink>
                                    </>

                                    : <>
                                        <NavLink to="/loyalty/overview" className="link text-center" activeClassName="active-link" id="side-nav-link-loyalty-loyalty">
                                            <FaIcon icon={faHeart} className="side-nav-icon" />
                                            <span className="link-text">Loyalty</span>
                                        </NavLink>

                                        <NavLink to="/engagement/campaigns" className="link text-center" activeClassName="active-link" id="side-nav-link-loyalty-engage">
                                            <FaIcon icon={faHandshake} className="side-nav-icon" />
                                            <span className="link-text">Engage</span>
                                        </NavLink>

                                        <NavLink to="/senders" className="link text-center" activeClassName="active-link" id="side-nav-link-inbound">
                                            <FaIcon icon={faIdBadge} className="side-nav-icon" />
                                            <span className="link-text"  >Senders</span>
                                        </NavLink>


                                        {scopes[Constants.USER_ACCESS_CONTROLS.MODULES.LOYALTY_ANALYTICS.moduleId].read &&
                                            <NavLink to="/analytics" className="link text-center text-dark" activeClassName="active-link" id="side-nav-link-loyalty-analytics">
                                                <FaIcon icon={faChartLine} className="side-nav-icon" />
                                                <span className="link-text">Analytics</span>
                                            </NavLink>
                                        }
                                    </>
                                }
                            </div>
                            <hr className="w-80" />
                            <div>
                                <small className="link-header">Developer</small>

                                <a href={"https://app.lite.getshoutout.com?token=" + token} rel="noopener noreferrer" target="_blank" className="link text-center">
                                    <FaIcon icon={faCode} className="side-nav-icon" />
                                    <span className="link-text">SMS API</span>
                                </a>

                                {/* <Nav.Item onClick={toggleProducts} className={`cursor-pointer link text-center text-dark ${productsMenuShow ? "active-link" : ''}`} id="side-nav-link-inbound">
                                <FaIcon icon={faCode} className="side-nav-icon" />
                                <span className="link-text">SMS API</span>
                            </Nav.Item>
                            <div className={productsMenuShow ? "d-flex flex-column" : "d-none"}>
                                <a href={"https://app.lite.getshoutout.com/getting-started/sms-api?token=" + token} rel="noopener noreferrer" target="_blank" className="text-dark link-sub p-0 pl-3"><small>Send SMS</small></a>
                                <a href={"https://app.lite.getshoutout.com/getting-started/sms-incoming?token=" + token} rel="noopener noreferrer" target="_blank" className="text-dark link-sub p-0 pl-3"><small>Receive SMS</small></a>
                                <a href={"https://app.lite.getshoutout.com/getting-started/otp?token=" + token} rel="noopener noreferrer" target="_blank" className="text-dark link-sub p-0 pl-3"><small>Verify Number</small></a>
                            </div> */}
                            </div>
                            <hr className="w-80" />
                            <div>
                                <small className="link-header">Help</small>
                                {/* <Nav.Item
                                onClick={triggerSupportChat}
                                className="cursor-pointer link text-center no-focus-color p-2" activeClassName="active-link">
                                <FaIcon icon={faComment} className="side-nav-icon" />
                                <span className="link-text text-dark">Chat With Us</span>
                            </Nav.Item> */}

                                <a href="https://support.getshoutout.com/" target="_blank" className="link text-center text-dark" id="side-nav-link-loyalty-loyalty">
                                    <FaIcon icon={faBook} className="side-nav-icon" />
                                    <span className="link-text">User Guides</span>
                                </a>
                                {
                                    (userRole === 'admin' || userRole === "finance") ?
                                        <NavLink to="/administrator/users" className="link text-center mb-5" activeClassName="active-link" id="side-nav-link-admin">
                                            <FaIcon icon={faBriefcase} className="side-nav-icon" />
                                            <span className="link-text"  >Admin</span>
                                        </NavLink>
                                        : null
                                }
                            </div>


                        </div>
                    )
                }
            }
        </UserContext.Consumer>
    );


}

export default withRouter(SideNavigationBar);